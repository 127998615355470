
.visit-container  .p-tabview-nav-container {
    margin-left: 150px !important;
    margin-right: 150px !important;
    margin-top: 50px !important;
    justify-content: center !important;
}
.visit-container  .p-tabview-panels{
    margin-left: 100px !important;
    margin-right: 100px !important;
    justify-content: center !important;
}
#visit-container {
    display: inline-block;
    margin: 20px;
   
    overflow: hidden;            
  }
#visit-container .visit-image {
    display: block;
    transition: transform .4s;   
}
#visit-container .visit-image:hover {
    transform: scale(1.3);
    transform-origin: 50% 50%;
}
/* .container-text{
    margin-left: 140px;
    margin-right: 140px;
} */
.evisa-button{
    background-color: #004d99;
    color: #fff;
    font-weight: 600;
}
.evisa-title{
    color:#0D4D99;
    font-weight: 700;
}
.visit-body{
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
}
@media only screen and (min-width: 900px) {
    .container-text{
        margin-left: 140px;
        margin-right: 140px;
    }
    .parent-container{
        margin-left: 100px;
        margin-right: 100px;
    }
}
@media only screen and (max-width: 899px) {
  
    .parent-container{
        margin-left: 10px;
        margin-right: 10px;
    }
    .container-text{
        margin-left: 10px;
        margin-right: 10px;
    }
}
.parent-container{
    display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
