.form-demo .card {
  min-width: 450px;
}
.form-demo .card form {
  margin-top: 2rem;
}
.form-demo .card .field {
  margin-bottom: 1.5rem;
}
@media screen and (max-width: 960px) {
  .form-demo .card {
    width: 80%;
  }
}
@media screen and (max-width: 640px) {
  .form-demo .card {
    width: 100%;
    min-width: 0;
  }
}
#firstName {
  height: 42px;
}
#lastName {
  height: 42px;
}
#username {
  height: 42px;
}
#email {
  height: 42px;
}
#password {
  height: 42px;
}
#telephone {
  height: 42px;
}
#regionId {
  height: 42px;
}
#zone {
  height: 42px;
}
#city {
  height: 42px;
}
#subcity {
  height: 42px;
}

#woreda {
  height: 42px;
}
#kebele {
  height: 42px;
}
#name {
  height: 42px;
}
#phoneNumber {
  height: 42px;
}
#companyManagerEmailAddress {
  height: 42px;
}
#companyCategoryId {
  height: 42px;
}
#tinCertificateIssuedDate {
  height: 42px;
}
#businessLicenseIssuedDate {
  height: 42px;
}
#businessLicenseExpiryDate {
  height: 42px;
}
#passportExpiryDate {
  height: 42px;
}
#visaExpiryDate {
    height: 42px;
  }
  #submit{
    height: 42px;
  }