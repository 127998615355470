.evisa-color{
    background-color: #0D4D99;
    color: #fff;
    
}
.primary-color{
    color:#17a2b8;
}
.text-left{
    text-align: left;
}
.evisa-title{
    color:#0D4D99;
    font-weight: 700;
}
.evisa-body{
    color:#0D4D99;
}
.evisa-button{
    background-color: #004d99;
    color: #fff;
    font-weight: 600;
}
.divider{
    height: 2px;
    width:100%;
    background-color: #004d99 ;
    display: inline-block;

}
.div-height{
    height: 80px;
}
/* #passportNumber, #referenceNumber input:focus {
    background-color: rgb(255, 255, 255);
}
  
#passportNumber, #referenceNumber input:not([value = ""]){
    
    border: 2px solid #17a2b8 ;  
}

span > .p-inputtext:enabled:focus{
    border-color: #0D4D99 !important;
    box-shadow: 0 0 0 0.02rem #0D4D99 !important;
}
span > .p-inputtext:enabled:hover{
    border-color: #474646 !important;
    box-shadow: 0 0 0 0.01rem #0073ff !important;
} */