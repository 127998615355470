
.custom-panel {  
   justify-content: left;
   text-align: left;
} 
.ant-collapse-header{
  color: #0547ac !important;
  font-weight: 500;
  font-size: 13pt;
  font-family: 'Roboto', sans-serif;
  
}
.ant-collapse-header:hover{
  background-color: aliceblue;
}
.evisa-title{
  color:#0D4D99;
  font-weight: 700;
}
@media only screen and (min-width: 900px) {
  .parentAccord{
    margin-left: 20%;
    margin-right: 20%; 
   
  }
}
@media only screen and (max-width: 899px) {
  .parentAccord{
    margin-left: 20px;
    margin-right: 20px;
    
  }

}
 .ant-collapse-content-box{
  font-size: 12pt !important;
  box-shadow: rgba(212, 221, 231, 0.2) 0px 8px 24px !important;
  font-family: 'Roboto', sans-serif;
}
  
 








