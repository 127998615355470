footer{
    color: white;
    background: #0e2a47;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  }
  footer a{
    color: #bfbfbf;
  }
  footer a:hover{
    color: white;
  }
  
  
  .footer-bottom{
    background: #0e2a47;
    padding: 1em;
    color: #a6a6a6;
  }
  .footer-top{
    background: #0e2a47;  
  }
  .footer-middle{
     background: #0e2a47;
    padding-top: 2em;
    color: white;
  }
  .subnavigation-container{
    background: #0e2a47;
  }
  .subnavigation .nav-link{
    color: white;
    /* font-weight: bold; */
  }
  .subnavigation-container{
    text-align: left;
  }
  .subnavigation-container .navbar{
    display: inline-block;
    margin-bottom: -6px; 
  }
  .col-subnav a{
    padding: 1rem 1rem;
    color: white;
    /* font-weight: bold; */
  }
  .col-subnav .active{
    border-top:5px solid orange;
   background: #0e2a47;
    color:white;
  }
  .list-unstyled li{
    text-decoration: none!important;
    text-align: left;
    
  }
  .footer-title{
    text-align: left;
  }
  .main-footer{
    position:relative;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%; 
    color: white;
 }
 .footer-link{
    text-decoration-line: none!important;
 }
 .evisa-footer-logo {
  background: #0e2a47;
  margin-top: 50px;
 }
 .evisa-footer-logo img{
  background: #0e2a47;
  margin-right: 15px;
  margin-top: 7px;;
 }
 .footer-break{
  background: #0e2a47;
  border: 1px solid white;
  margin-top: 10px!important;
  margin-bottom: 5px!important;

 }
 .evisa-external-link a{
  color: #bfbfbf;
text-decoration: none;
 }

 @media only screen and (max-width: 995px) {
 .list-unstyled li{
  text-decoration: none!important;
  text-align: center;
}
.footer-title{
  text-align: center;
}
.dropdown-backdrop{
  position: static;
}
 }
 