.term-wrap {
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    height: 100vh;
    padding: 2rem;
    background-color: #eee;
  }
  
  .term-container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 1rem;
    background-color: #fff;
    width: 768px;
    
    border-radius: 0.25rem;
    box-shadow: 0rem 1rem 2rem -0.25rem rgba(0, 0, 0, 0.25);
  }  
    .term-container__heading {
      padding: 1rem 0;
      border-bottom: 1px solid #ccc;
      text-align: center;
    } 
      .term-container > h2 {
        font-size: 1.75rem;
        line-height: 1.75rem;
        margin: 0;
      }
    
    
    .term-container__content {
      flex-grow: 1;
     
    }
    
    .term-container__nav {
      border-top: 1px solid #ccc;
      text-align: right;
      padding: 2rem 0 1rem;
    }
      .term-container > .button {
        background-color: #444499;
        box-shadow: 0rem 0.5rem 1rem -0.125rem rgba(0, 0, 0, 0.25);
        padding: 0.8rem 2rem;
        border-radius: 0.5rem;
        color: #fff;
        text-decoration: none;
        font-size: 0.9rem;
        transition: transform 0.25s, box-shadow 0.25s;
        
    
      }
      
.term-container > small {
        color: #777;
        margin-right: 1rem;
}
    
@media only screen and (max-width: 899px) {
  .term-wrap {
    margin-top: 150px;
    margin-bottom: 150px;
  }
} 