/* .evisa-nav-dropdown ul li p {
  border: none;
  cursor: pointer;
  width: 100%;
  background: #f0f0f0;
} */

.evisa-nav-dropdown ul li {
  display: block;
  text-decoration: none;
}
.evisa-nav-dropdown ul li,
ul li p {
  padding: 0.5rem 1rem;
  text-align: left;
}
.evisa-nav-dropdown .dropdown {
  padding: 0px 1.5rem;
  height: 0;
  overflow: hidden;
  transition: height ease 0.2s;
}
.evisa-nav-dropdown-visa ul li {
  display: block;
  text-decoration: none;
}
.evisa-nav-dropdown-visa ul li,
ul li p {
  padding: 0.5rem 1rem;
  text-align: left;
}
.evisa-nav-dropdown-visa .dropdown {
  padding: 0px 1.5rem;
  height: 0;
  overflow: hidden;
  transition: height ease 0.2s;
}
  ul li :hover,
  ul li p:hover {
    color: #0D4D99 !important;
  }

.evisa-nav-dropdown .dropdown {
  position: absolute;
  left: -10px;
  top: 30px;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  z-index: 99;
  padding: 0;
  background-color: #f2f2f2;
  border-radius: 0 0 0.5rem 0.5rem;
  width: 190px;
}

.evisa-nav-dropdown-visa .dropdown {
  position: absolute;
  left: -20px;
  top: 40px;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  z-index: 99;
  padding: 0;
  background-color: #f2f2f2;
  border-radius: 0 0 0.5rem 0.5rem;
  width: 140px;
}


ul li:hover .dropdown {
  height: 90px;
  padding-left: 12px;
} 
.evisa-nav-p{
float: right!important;}

@media only screen and (max-width: 995px) {
  #navbarNavDropdown {
    margin-top: 20px;
  }
  .evisa-nav-p ul li,
ul li p {
  padding: 0;
  text-align: left;
}
.mobile-link-disp{
display: block!important;
}
.mobile-link-comp{
  display: none!important;
}
.desktop-image{
  display: none!important;
}
.mobile-image{
  display: block!important;
  width: 170px!important;
}
}

@media (max-width:1120px) {
  .desktop-image{
    width: 260px!important;
  }
}
.evisa-nav-button{
  margin-top: 10px!important;
  margin-right: 0px!important;
}

