
body {
  overflow-x: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.evisa-nav{
  background-color: white!important;
  border-radius: 1px;
  border-style: outset   ;
  height: 130px;
  box-shadow: 5px 3px 8px #999999;

}
.country-list ul li{
  list-style: none;
  padding: 4px;
  text-align: left;
  } 
  .request-card{
    height: 120px;
    background-color: #004d99;
    margin-bottom: 20px;
    margin-left: 40px;
    margin-right: 80px;
    border: none!important;

  }
  .card__content{
    background-color: #004d99;
    border-color: #004d99;
    border: none!important;
  }

  .start-application-icon{
    font-size: 40px;
    margin-top: 10px;
  }

  .request-card .card{
border: none;
margin-top: 20px;
text-decoration-line: none;

  }
  .company-form-label{
    float: left;
  }
.align-left{
  float: left;
  text-align: left;
}
.align-right{
  float: right;
  text-align: right;
}
.evisa-status-succes {
  background-color: #006400;
  height: 140px
}
.paymentstatusbody {
  background: #fff;
  padding: 20px;
  border-top: 0;
  color: #666;
  border: 1px solid #ccc;
  background-color: #f2f2f2
}
.paymentcancel {
  background-color: #8b0000;
  height: 140px
}
.evisa-paymenterror {
  background-color: #8b0000;
  height: 140px
}
.evisa-pending {
  background-color: #00008b;
  height: 140px
}
.active .visa-nav-item {
  color: #0f6af0!important;
}
nav a {
  display: flex;
  align-items: center;
  /* margin-left: 70px; */
  margin-right: 12px;
  height: 100%;
  text-decoration: none;
}

.btn.btn-lg {padding: 10px 40px;}
.btn.btn-hero,
.btn.btn-hero:hover,
.btn.btn-hero:focus {
    color: #f5f5f5;
    background-color: #1abc9c;
    border-color: #1abc9c;
    outline: none;
    margin-top: -200px auto;
    z-index: 209px;
}
.card-img-top-download{
  width: 65px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top:35px;
}
.card-img-top-visa-card{
  width: 100%;
  height: auto;
}
.carousel-caption {
  top: auto;
  bottom: 135px;
}
.evisa-courasel-btn{
  background-color: #FCDD09;
  width: 180px;
padding: 10px!important;
padding-bottom: 0px!important;}

.accordion-item-festival-content{
  margin-top: 300px;
}
.card-text-hover-phar{
  display: none;
}
.accordion-festival:hover .card-text-hover-phar {
display: block;
}
.accordion-festival:hover .accordion-item-festival-content {
  margin-top: 30px;
  }
.accordion-festival {
  background-image: url(/src/assets/Images/great-run.jpg);
  background-position:center;
  background-size: 34.5rem;
  padding: 1em;
  height: 28rem;
  width: 100%;
  color: #eee;

}
 .accordion-item-festival:nth-child(2) {
  background: rgba(0,0,0,0)!important;
  color: white;
  border: none;
}

.accordion-item-advanture-content{
  margin-top: 300px;
}
.card-text-advanture-hover-phar{
  display: none;
}
.accordion-advanture:hover .card-text-advanture-hover-phar {
display: block;
}
.accordion-advanture:hover .accordion-item-advanture-content {
  margin-top: 30px;
  }
.accordion-advanture {
  background-image: url(/src/assets/Images/ertale-dest.jpg);
  background-position:center;
  background-size: 34.5rem;
  padding: 1em;
  height: 28rem;
  width: 100%;
  color: #eee;
}
 .accordion-item-advanture:nth-child(2) {
  background: rgba(0,0,0,0)!important;
  color: white;
  border: none;
}

.accordion-item-historical-content{
  margin-top: 300px;
}
.card-text-historical-hover-phar{
  display: none;
}
.accordion-historical:hover .card-text-historical-hover-phar {
display: block;
}
.accordion-historical:hover .accordion-item-historical-content {
  margin-top: 30px;
  }
.accordion-historical {
  background-image: url(/src/assets/Images/gondar-dest.jpg);
  background-position:center;
  background-size: 34.5rem;
  padding: 1em;
  height: 28rem;
  width: 100%;
  color: #eee;
}
 .accordion-item-historical:nth-child(2) {
  background: rgba(0,0,0,0)!important;
  color: white;
  border: none;
}

.accordion-item-natural-content{
  margin-top: 300px;
}
.card-text-natural-hover-phar{
  display: none;
}
.accordion-natural:hover .card-text-natural-hover-phar {
display: block;
}
.accordion-natural:hover .accordion-item-natural-content {
  margin-top: 30px;
  }
.accordion-natural {
  background-image: url(/src/assets/Images/abay-dest.jpg);
  background-position:center;
  background-size: 34.5rem;
  padding: 1em;
  height: 28rem;
  width: 100%;
  color: #eee;
}
 .accordion-item-advanture:nth-child(2) {
  background: rgba(0,0,0,0)!important;
  color: white;
  border: none;
}

@media only screen and (max-width:995px ) {
  .courasel-caption-text{
    font-size: 21px!important;
  }
  .evisa-link-card{
    margin: 0px!important;
  }
  
}


@media only screen and (max-width:800px ) {
  .mobile-slide-img{
    display: none!important;
  }
  .tab-slide-img{
    display: block!important;
  }
  .desktop-slide-img{
    display: none!important;
  }
}

@media only screen and (max-width:400px ) {
  .mobile-slide-img{
    display: block!important;
  }
  .tab-slide-img{
    display: none!important;
  }
  .desktop-slide-img{
    display: none!important;
  }
}