a:link {
    
    text-decoration: none;
  }
  a:visited {
   
    text-decoration: none;
  }
  
  a:hover {
    
    text-decoration: none;
  }
  a:active {
   
    text-decoration: none;
  } 
ListGroup.Item:hover{
    
    background-color: #b4b1b1;
}