
.pay-card {
    margin-left: 50px;
  
    margin-top: 20px;
  }

@media only screen and (max-width: 899px) {
    .pay-card {
      margin-left: 0px;
      padding-right: 10px;
      margin-top: 20px;
    }
  }